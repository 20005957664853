(function () {
    angular.module('informaApp')
        .service('BoxPlotItem', ['d3', 'ChartElementsHelper', BoxPlotItemService])


    function BoxPlotItemService(d3, ChartElementsHelper) {
        return function (container, config) {
            var boxContainer = container.append('g')
                .attr('class', 'box-plot-item');

            createBox(boxContainer, config.width, config.q1 - config.median, config.x, config.median, config.color);
            createBox(boxContainer, config.width, config.median - config.q3, config.x, config.q3, config.color);

            createBoxBorder(boxContainer, config.width, config.q1 - config.q3, config.x, config.q3);

            createMedianLine(boxContainer, config.width, config.x, config.median);

            if (config.upperBorder < config.q3) {
                createWhisker(boxContainer, config.x, config.q3, config.upperBorder, config.width);
            }

            if (config.lowerBorder > config.q1) {
                createWhisker(boxContainer, config.x, config.q1, config.lowerBorder, config.width);
            }

            _.forEach(config.outliers, function (outlier) {
                createOutlier(boxContainer, config.x, outlier, config.color);
            });

            this.container = boxContainer;
        };

        function createOutlier(container, x, y, color) {
            container.append('circle')
                .attr('fill', color)
                .attr('r', 3)
                .attr('cx', x)
                .attr('cy', y)
                .attr('stroke', 'rgba(0, 0, 0, 0.5)')
                .attr('stroke-width', 1);
        }

        function createWhisker(container, x, y1, y2, width) {
            container.append('line')
                .attr('class', 'plot')
                .attr('stroke', 'black')
                .attr('stroke-width', 1)
                .attr('x1', x)
                .attr('x2', x)
                .attr('y1', y1)
                .attr('y2', y2);

            container.append('line')
                .attr('class', 'plot')
                .attr('stroke', 'black')
                .attr('stroke-width', 1)
                .attr('x1', x - width / 4)
                .attr('x2', x + width / 4)
                .attr('y1', y2)
                .attr('y2', y2)
        }

        function createBox(container, width, height, cx, y, color) {
            container.append('rect')
                .attr('class', 'box')
                .attr('width', width)
                .attr('height', height)
                .attr('transform', ChartElementsHelper.translate(cx - width / 2, y))
                .attr('fill', color)
        }

        function createBoxBorder(container, width, height, cx, y) {
            container.append('rect')
                .attr('class', 'box-border')
                .attr('width', width)
                .attr('height', height)
                .attr('transform', ChartElementsHelper.translate(cx - width / 2, y))
                .attr('fill', 'rgba(0,0,0,0)')
                .attr('stroke', 'rgba(0, 0, 0, 0.5)')
                .attr('stroke-width', 2);
        }

        function createMedianLine(container, width, x, y) {
            container.append('line')
                .attr('class', 'plot')
                .attr('stroke', 'black')
                .attr('stroke-width', 2)
                .attr('x1', x - width / 2)
                .attr('x2', x + width / 2)
                .attr('y1', y)
                .attr('y2', y)
        }
    }
})();